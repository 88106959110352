import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/index'),
  },
  {
    path:"/login",
    name:"Login",
    component: () => import('@/views/login/index'),
  },
  {
    path:"/forget",
    name:"Forget",
    component: () => import('@/views/forget/index'),
  },
  {
    path:"/register",
    name:"Register",
    component: () => import('@/views/register/index'),
  },
  {
    path:"/about",
    name:"About",
    component: () => import('@/views/about/index'),
  },
  {
    path:"/serve",
    name:"Serve",
    component: () => import('@/views/serve/index'),
  },
  {
    path:"/policy",
    name:"Policy",
    component: () => import('@/views/policy/index'),
  },
  {
    path:"/blog",
    name:"Blog",
    component: () => import('@/views/blog/index'),
  },
  {
    path:"/blog-detail",
    name:"BlogDetail",
    component: () => import('@/views/blogDetail/index'),
  },
  {
    path:"/faq",
    name:"Faq",
    component: () => import('@/views/faq/index'),
  },
  {
    path:"/help",
    name:"Help",
    component: () => import('@/views/help/index'),
  },
  {
    path:"/protocol",
    name:"Protocol",
    component: () => import('@/views/protocol/index'),
  }
]

const router = new VueRouter({
  base:"./",
  routes,
  mode:"history"
})

export default router
